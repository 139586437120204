import axios from "axios";
import GlobalBackground from "../../assets/img/global-bakground.webp"
import { useEffect, useState } from "react";
import Module from "../../library/module";
import Element from "../../library/element";

function Feiern() {

    const [Data, setData] = useState({ image: "", slogan: "", title: "", text: "", phone: "", email: "" })
    const [Dates, setDates] = useState("")
    const [Gender, setGender] = useState("")
    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Error, setError] = useState({ status: true, type: "", text: "" })

    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_feiern'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"],
                phone: r["phone"],
                email: r["email"]
            })
        }
    }
    const Control = () => {
        if (Dates === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Gender === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (User === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Email === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Phone === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Comment === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            const root = Element({ element: "root", root: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Veranstaltungsanfrage erhalten. Die folgenden Informationen gehören dem Antragsteller. Bitte setzen Sie sich mit dem Bewerber in Verbindung.
                <br/><br/>
                Gewünschtes Datum: ${Dates}<br/>
                Anrede: ${Gender}<br/>
                Name und Vorname: ${User}<br/>
                Telefon: ${Phone}<br/>
                E-Mail: ${Email}<br/>
                Bemerkungen: ${Comment}<br/>
            `
            const companyData = {
                id: "20242118",
                email: ["info@ristorante-olivo.ch", "n.n.akcakaya@gmail.com"],
                title: "Veranstaltungsanfrage",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })
            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${User}` : `Sehr geehrter ${Gender} ${User}`}<br/><br/>
                Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br/><br/>
                Freundlichen Grüsse<br/><br/>
                Olivio Team<br/><br/>
                Ristorante Olivo<br/>
                Bahnhofstrasse 4 <br/>
                8810 Horgen<br/><br/>
                info@ristorante-olivo.ch<br/>
                044 534 99 99<br/>
            `;
            const customerData = {
                id: "20242118",
                email: ["" + Email + ""],
                title: "Ihre Nachricht ist bei uns angekommen",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Vielen Dank für Ihre Reservierungsanfrage! Ihre Reservationsbestätigung wird Ihnen per E-Mail zugeschickt." });
            setDates("")
            setGender("")
            setUser("")
            setEmail("")
            setPhone("")
            setComment("")
        }

    }
    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            <Module module="section" section={{ type: "form", url: GlobalBackground, classname: "" }}>
                <div className="row m-0">
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url(${Data.image})` }} className="booking-image"></div>
                    </div>
                    <div className="col-lg-6 lg-pt-15 lg-pb-5 md-pb-2 md-pt-2">
                        <div className="d-flex justify-content-center">
                            <div className="w-80">
                                <div className="jobs-title">{Data.title}</div>
                                <div className="jobs-text mt-2" dangerouslySetInnerHTML={{ __html: Data.text }}></div>
                                <div className="jobs-slogan">{Data.slogan}</div>
                            </div>
                        </div>
                        <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                            {Error.text}
                        </div>
                        <div className="card mt-1">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Datum auswählen  *</label>
                                            <Module module="calendar" calendar={{ response: (e) => { setDates(e) } }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <div className="mb-2">
                                                <select className="form-control h-50px shadow-sm cursor-pointer" onChange={(e) => { setGender(e.target.value) }}>
                                                    <option value={0}>Andrede</option>
                                                    <option value={"Frau"}>Frau</option>
                                                    <option value={"Herr"}>Herr</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <input className="form-control h-50px shadow-sm" placeholder="Name und Vorname *" value={User} onChange={(e) => { setUser(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <input className="form-control h-50px shadow-sm" placeholder="E-Mail Adresse *" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <input className="form-control h-50px shadow-sm" placeholder="Telefon   *" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <textarea className="form-control shadow-sm" placeholder="Bemerkungen *" rows={5} value={Comment} onChange={(e) => { setComment(e.target.value) }}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <button className="btn btn-olivio w-100" onClick={() => { Send() }}>Absenden</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}

export default Feiern;