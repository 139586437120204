import axios from "axios";
import GlobalBackground from "../../assets/img/global-bakground.webp"
import ContactImage from "../../assets/img/contact.webp"
import { useEffect, useState } from "react";
import Module from "../../library/module";
import Element from "../../library/element";

function Contact() {
    const [Gender, setGender] = useState("")
    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Error, setError] = useState({ status: true, type: "", text: "" })

    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_feiern'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);

        }
    }
    const Control = () => {
        if (Gender === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (User === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Email === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Phone === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Comment === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            const root = Element({ element: "root", root: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf.
                <br/><br/>
                Anrede: ${Gender}<br/>
                Name und Vorname: ${User}<br/>
                Telefon: ${Phone}<br/>
                E-Mail: ${Email}<br/>
                Bemerkungen: ${Comment}<br/>
            `
            const companyData = {
                id: "20242118",
                email: ["info@ristorante-olivo.ch", "n.n.akcakaya@gmail.com"],
                title: "Neue Nachricht",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })


            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${User}` : `Sehr geehrter ${Gender} ${User}`}
                <br/><br/>
                Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.
                <br/><br/>
                Freundlichen Grüsse
                <br/><br/>
                Olivio Team
                <br/><br/>
                Ristorante Olivo<br/>
                Bahnhofstrasse 4<br/>
                8810 Horgen<br/><br/>
                info@ristorante-olivo.ch<br/>
                044 534 99 99<br/>
            `;
            const customerData = {
                id: "20242118",
                email: ["" + Email + ""],
                title: "Ihre Nachricht ist bei uns angekommen",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf." });
            setGender("")
            setUser("")
            setEmail("")
            setPhone("")
            setComment("")
        }
    }
    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            <Module module="section" section={{ type: "form", url: GlobalBackground, classname: "container-inner" }}>
                <div className="row m-0 d-flex justify-content-center">
                    <div className="col-lg-12 lg-pt-10 lg-pb-2 md-pb-2 md-pt-2">
                        <div className="d-flex justify-content-center">
                            <div className="w-80">
                                <div className="jobs-title">Kontaktformular</div>
                                <div className="jobs-text mt-2">
                                    Schicken Sie uns Ihren Kommentar. <br />
                                    Gerne bearbeiten wir Ihre Anfrage während der Bürozeiten von Montag – Freitag zwischen 09.00-18.00 Uhr.<br />
                                    Vielen herzlichen Dank!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 lg-pt-2 lg-pb-1 md-pb-1 md-pt-1">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center">
                                <i className="fa-solid fa-square-phone-flip fs-2hx mb-3 color-olivo"></i>
                                <div className="contact-title">Telefon</div>
                                <div className="contact-link" onClick={() => { window.open("tel:044 534 99 99") }}>044 534 99 99</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 lg-pt-2 lg-pb-1 md-pb-1 md-pt-1">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center ">
                                <i className="fa-solid fa-envelope fs-2hx mb-3 color-olivo"></i>
                                <div className="contact-title">E-Mail</div>
                                <div className="contact-link" onClick={() => { window.open("mailto:info@ristorante-olivo.ch") }}>info@ristorante-olivo.ch</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 lg-pt-2 lg-pb-1 md-pb-1 md-pt-1">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center">
                                <i className="fa-solid fa-location-dot fs-2hx mb-3 color-olivo"></i>
                                <div className="contact-title">Routenplaner</div>
                                <div className="contact-link" onClick={() => { window.open("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10830.86503627641!2d8.5965154!3d47.2612514!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa8c9021b4f0b%3A0xde1c4e924831c1ef!2sOlivo!5e0!3m2!1str!2str!4v1697746872303!5m2!1str!2str") }}>
                                    Unser Standort
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 lg-pt-1 lg-pb-0 md-pb-2 md-pt-2">
                        <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                            {Error.text}
                        </div>
                    </div>
                    <div className="col-lg-8 lg-pt-1 lg-pb-2 md-pb-2 md-pt-2">
                        <div className="card calc-height">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Andrede *</label>
                                            <select className="form-control h-50px shadow-sm cursor-pointer" onChange={(e) => { setGender(e.target.value) }}>
                                                <option value={"Frau"}>Frau</option>
                                                <option value={"Herr"}>Herr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Name und Vorname *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Name und Vorname" value={User} onChange={(e) => { setUser(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">E-Mail *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="E-Mail" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Telefon *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Telefon" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-4 lg-pb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Nachricht *</label>
                                            <textarea className="form-control shadow-sm" placeholder="Nachricht..." rows={5} value={Comment} onChange={(e) => { setComment(e.target.value) }}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <button className="btn btn-olivio w-100" onClick={() => { Send() }}>SENDEN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 lg-pt-1 lg-pb-2 md-pb-2 md-pt-2">
                        <div className="card calc-height">
                            <img src={ContactImage} className="contact-image lg-pb-1" />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 lg-pb-3">
                                        <div className="d-flex">
                                            <div className="w-50px">
                                                <i className="fa-solid fa-location-dot mb-3 mt-1" style={{ fontSize: "26px", color: "#7f8367" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-title mb-1">Adress</div>
                                                Ristorante Olivo <br />
                                                Bahnhofstrasse 4 <br />
                                                8810 Horgen
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 lg-pb-3">
                                        <div className="d-flex">
                                            <div className="w-50px">
                                                <i className="fa-solid fa-square-phone-flip fs-2 mb-3 mt-1" style={{ fontSize: "26px", color: "#7f8367" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-title mb-1">Telefon</div>
                                                044 534 99 99
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 lg-pb-3">
                                        <div className="d-flex">
                                            <div className="w-50px">
                                                <i className="fa-solid fa-envelope fs-2 mb-3 mt-1" style={{ fontSize: "26px", color: "#7f8367" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-title mb-1">E-mail</div>
                                                info@ristorante-olivo.ch
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>



            <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10830.86503627641!2d8.5965154!3d47.2612514!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa8c9021b4f0b%3A0xde1c4e924831c1ef!2sOlivo!5e0!3m2!1str!2str!4v1697746872303!5m2!1str!2str"></iframe>
        </>
    )
}
export default Contact;