import Module from "../../library/module";
import GlobalBackground from "../../assets/img/global-bakground.webp"
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Element from "../../library/element";
function Menu() {
    const { name } = useParams()
    const navigate = useNavigate()

    const [MenuData, setMenuData] = useState([])
    const [FileData, setFileData] = useState([])
    const [LangData, setLangData] = useState("DEUTSCH")
    const [TitleData, setTitleData] = useState("")
    const MenuDataLoad = async () => {
        const response = (await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_menu&conditions=company_id='20242118'`)).data
        setMenuData(response)
        setFileData(response)
    }
    const MenuLangLoad = ({ name }) => {
        var lang = [];
        MenuData.filter((f) => { return (Element({ element: "seo", seo: { url: f["title"] } }).includes(name)) }).forEach((d, x) => {
            var res = JSON.parse(d["lang"]);
            lang[x] = { id: res["id"], language: res["language"] }
        });


        if (lang.length === 2) {

            return (
                <>
                    <div className={`row d-lg-flex justify-content-center mt-2 mb-3`}>
                        {
                            (lang || []).map((d, x) => {
                                return (
                                    <>
                                        <div className="col-lg-3 col-6">
                                            <button className={`btn btn-olivio w-100 me-2 mb-2 ${d["language"] === LangData ? "active" : ""}`} onClick={() => { setLangData(d["language"]) }}>
                                                {d["language"]}
                                            </button>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </>
            )

        }
    }
    const MenuFileLoad = ({ name, lang }) => {
        const [TagesMenu, setTagesMenu] = useState("")
        const [Loader, setLoader] = useState(null)

        const response = FileData.filter((d) => { return (Element({ element: "seo", seo: { url: d["title"] } }).includes(name) && JSON.parse(d["lang"])["language"] === lang) })
        if (response.length > 0) {
            const title = response[0]["title"];
            const file = JSON.parse(response[0]["file"]);
            var result = [];
            setTitleData(title)
            for (let i = 0; i < file.length; i++) {
                result[i] =
                    <div className="col-lg-6">
                        {
                            Loader === null ?
                                <img src={`${Element({ element: "root", root: "file" })}/${file[i]["blob"]}`} alt={file[i]["blob"]} className="border menu-image shadow-sm" />
                                :
                                Loader
                        }

                    </div>;
            }
        }
        const Tagesmenu = () => {
            axios.post(`https://ristorante-olivo.ch/olivio/class.pdf.php`, {}, { responseType: 'arraybuffer' }).then((data) => {
                const blob = new Blob([data.data], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                setTagesMenu(url)
            })
        }
        useEffect(() => {
            if (name === "tagesmenu" && TagesMenu.length === 0) {
                Tagesmenu()
            }
        }, [name, TagesMenu])

        useEffect(() => {
            setLoader(
                <div className="loader-container border">
                    <div className="loader"></div>
                </div>
            )
            setTimeout(() => {
                setLoader(null)
            }, 2000);
        }, [name])

        return (
            <>
                {
                    name === "tagesmenu" ?
                        <div className="row mt-2 mb-2 d-flex justify-content-center">
                            <div className="col-lg-6">
                                {
                                    Loader === null ?
                                        <img src={TagesMenu} alt={TagesMenu} className="border menu-image shadow-sm" />
                                        :
                                        Loader
                                }

                            </div>
                        </div>
                        :
                        <div className="row d-flex justify-content-center mb-3">
                            {result}
                        </div>
                }

            </>
        )
    }
    const MenuRootLoad = () => {

        return (
            <>
                <button className={`btn btn-olivio w-100 me-2 mb-2 ${name === "tagesmenu" ? "active" : ""}`} onClick={() => { navigate(`/menu/tagesmenu`) }}>
                    TAGESMENÜ
                </button>
                {
                    MenuData.reduce((unique, d) => {
                        if (!unique.some(item => item.title === d.title)) {
                            unique.push(d);
                        }
                        return unique;
                    }, []).map((d, x) => {
                        return (
                            <button className={`btn btn-olivio w-100 me-2 mb-2 ${name === Element({ element: "seo", seo: { url: d["title"] } }) ? "active" : ""}`} onClick={() => { navigate(`/menu/${Element({ element: "seo", seo: { url: d["title"] } })}`) }}>
                                {d.title}
                            </button>
                        )
                    })
                }
            </>
        )
    }

    const [distanceFromBottom, setDistanceFromBottom] = useState(0);


    useEffect(() => {
        MenuDataLoad()
    }, [name])




    return (
        <>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="menu-title lg-pt-10 lg-pb-2 md-pb-2 md-pt-2">
                    {TitleData}
                </div>
                <div className="menu-language">
                    <MenuLangLoad name={name} />
                </div>
                <div className="menu-body">
                    <MenuFileLoad name={name} lang={LangData} />
                </div>
                <div className={`menu-route  ${distanceFromBottom === 1 ? "sticky-footer shadow-sm" : "mt-5 mb-5"}`}>
                    <MenuRootLoad />
                </div>
                {distanceFromBottom === 1 ? <div className="mt-5 mb-5">
                    <button className={`btn btn-olivio w-100 me-2 mb-2`} style={{ opacity: 0 }}>
                        TAGESMENÜ
                    </button>
                </div> : <></>}
            </Module>
        </>
    )
}
export default Menu;