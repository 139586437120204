

const Seo = ({ seo }) => {
    return seo.url.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
        .replace(/&/g, '-and-')
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '-')
        .replace(/^-*/, '')
        .replace(/-*$/, '');
}
const Root = ({ root }) => {
    switch (root) {
        case "default": return "https://ristorante-olivo.ch/api/api.php"
        case "upload": return "https://ristorante-olivo.ch/api/class.upload.php"
        case "file": return "https://ristorante-olivo.ch/api/upload"
        //case "default": return "http://localhost:8080/olivio/api.php"
    }
}

export default function Element({ element, seo, root }) {
    switch (element) {
        case "seo": return Seo({ seo: seo })
        case "root": return Root({ root: root })
        default: return <>No Select Element</>
    }



}