import axios from "axios";
import { useEffect, useState } from "react";
import Element from "../../library/element";

function Virtual() {
    const [Data, setData] = useState({ status: false, data: "" })
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_virtual_tour'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                status: true,
                data: <div className="loader-container border" style={{ backgroundColor: "#f0f0f0" }}>
                    <div className="loader"></div>
                </div>
            })
            setTimeout(() => {
                setData({ status: false, data: r["tour"] })
            }, 2000);
        }
    }
    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            {
                Data.status ?
                    Data.data
                    :
                    <iframe src={Data.data} allowfullscreen="allowfullscreen" style={{ border: "0px #ffffff none", marginBottom: "-10px" }} width="100%" height="800px" frameborder="0"></iframe>
            }
        </>
    )
}
export default Virtual;