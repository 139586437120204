import axios from "axios";
import GlobalBackground from "../../assets/img/global-bakground.webp"
import { useEffect, useState } from "react";
import Module from "../../library/module";
import Element from "../../library/element";

function Jobs() {

    const [Gender, setGender] = useState("")
    const [Name, setName] = useState("")
    const [Surname, setSurname] = useState("")
    const [Phone, setPhone] = useState("")
    const [Email, setEmail] = useState("")
    const [Position, setPosition] = useState("")
    const [Dates, setDates] = useState("")
    const [Commnet, setCommnet] = useState("")
    const [File, setFile] = useState("")
    const [Error, setError] = useState({ status: true, type: "", text: "" })
    const [Data, setData] = useState({ image: "", slogan: "", title: "", text: "" })

    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_jobs'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"]
            })
        }
    }
    const Control = () => {
        if (Dates === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Position === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Gender === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Name === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Surname === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Email === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Phone === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (Commnet === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        if (File === "") { setError({ status: false, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, }); return false }
        return true;
    }
    const Send = async () => {
        if (Control()) {
            const root = Element({ element: "root", root: "default" });
            const data = new FormData();
            data.append('file', File);
            const axdata = await axios.post(Element({ element: "root", root: "upload" }), data);
            //FIRMA
            const companyBody = `
                Sie haben eine neue Bewerbung erhalten. Die erforderlichen Informationen finden Sie unten.
                <br/><br/>
                Anrede: ${Gender}<br/>
                Name und Vorname: ${Name} ${Surname}<br/>
                Gewünschte eintritt: ${Dates}<br/>
                E-Mail: ${Email}<br/>
                Telefon: ${Phone}<br/>
                Gewünschte Stelle: ${Position}<br/>
                Bemerkungen: ${Commnet}<br/>
                CV Herunterladen: <a href="https://www.thawolo.ch/api/upload/${axdata.data.data}">CV</a><br/>
            `
            const companyData = {
                id: "20242118",
                email: ["info@ristorante-olivo.ch", "n.n.akcakaya@gmail.com"],
                title: "Sie haben eine neue Bewerbung erhalten",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })


            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${Name} ${Surname}` : `Sehr geehrter ${Gender} ${Name} ${Surname}`}<br/><br/>
                Ihre Bewerbung ist bei uns eingegangen. Vielen Dank für Ihr Interesse. Wir werden Ihre Bewerbung prüfen und uns schnellstmöglich bei Ihnen melden.<br/><br/>
                Details Ihrer Reservierung<br/>
                Freundlichen Grüsse<br/><br/>
                Olivio Team
                Ristorante Olivo<br/>
                Bahnhofstrasse 4 <br/>
                8810 Horgen<br/><br/>
                info@ristorante-olivo.ch<br/>
                044 534 99 99<br/>
            `;
            const customerData = {
                id: "20242118",
                email: ["" + Email + ""],
                title: "Ihre Bewerbung ist bei uns eingegangen.",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Vielen Dank für Ihre Reservierungsanfrage! Ihre Reservationsbestätigung wird Ihnen per E-Mail zugeschickt." });
            setGender("")
            setName("")
            setSurname("")
            setPhone("")
            setEmail("")
            setPosition("")
            setDates("")
            setCommnet("")
            setFile("")
        }
    }

    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            <Module module="section" section={{ type: "form", url: GlobalBackground, classname: "" }}>
                <div className="row m-0">
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url(${Data.image})` }} className="booking-image bg-position-top"></div>
                    </div>
                    <div className="col-lg-6 lg-pt-2 md-pb-2 md-pt-2">
                        <div className="d-flex justify-content-center lg-pb-3">
                            <div className="w-80">
                                <div className="jobs-title">{Data.title}</div>
                                <div className="jobs-text mt-2" dangerouslySetInnerHTML={{ __html: Data.text }}></div>
                                <div className="jobs-slogan">{Data.slogan}</div>
                            </div>
                        </div>
                        <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                            {Error.text}
                        </div>
                        <div className="card mt-1">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Andrede *</label>
                                            <select className="form-control h-50px shadow-sm cursor-pointer" onChange={(e) => { setGender(e.target.value) }}>
                                                <option value={0}>Andrede</option>
                                                <option value={"Frau"}>Frau</option>
                                                <option value={"Herr"}>Herr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2"></div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Nachname *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Nachname *" value={Name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Vorname *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Vorname *" value={Surname} onChange={(e) => { setSurname(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Telefon *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Telefon *" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">E-Mail *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="E-Mail *" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Gewünschte eintritt  *</label>
                                            <input className="form-control h-50px shadow-sm" placeholder="Gewünschte eintritt  *" value={Position} onChange={(e) => { setPosition(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Datum auswählen  *</label>
                                            <Module module="calendar" calendar={{ response: (e) => { setDates(e) } }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Bemerkungen  *</label>
                                            <textarea className="form-control shadow-sm" placeholder="Bemerkungen *" rows={5} value={Commnet} onChange={(e) => { setCommnet(e.target.value) }}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Bewerbungsdossier hinzufügen *</label>
                                            <Module module="upload" upload={{ response: (e) => { setFile(e) } }} />
                                            <label className="mb-1 label-text">Maximal 5 Dateien möglich. 10 MB Limit. Erlaubte Dateitypen: pdf.</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 md-p-0">
                                        <div className="mb-2">
                                            <button className="btn btn-olivio w-100" onClick={() => { Send() }}>Absenden</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Jobs;