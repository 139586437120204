import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/page";
import Menu from "./pages/menu/page";
import Booking from "./pages/booking/page";
import Galery from "./pages/galery/page";
import Virtual from "./pages/virtual/page";
import Jobs from "./pages/jobs/page";
import Contact from "./pages/contact/page";
import Module from "./library/module";
import "./assets/css/bootstrap.css"
import "./assets/css/style.css"
import "./assets/css/fontawsome/css/all.css"
import "./assets/css/fontawsome/css/brands.css"
import "./assets/css/fontawsome/css/fontawesome.css"
import "./assets/css/fontawsome/css/regular.css"
import "./assets/css/fontawsome/css/solid.css"
import "./assets/css/fontawsome/css/svg-with-js.css"
import "./assets/css/fontawsome/css/v4-font-face.css"
import "./assets/css/fontawsome/css/v4-shims.css"
import "./assets/css/fontawsome/css/v5-font-face.css"
import Background from "./assets/img/footer-background.png"
import Product from "./pages/product/page";
import Feiern from "./pages/feiern/page";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function Router() {
  return (
    <>
      <Module module="header" header={{ url: Background }} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu/:name" element={<Menu />} />
        <Route path="/tisch-reservation" element={<Booking />} />
        <Route path="/galerie" element={<Galery />} />
        <Route path="/virtual-tour" element={<Virtual />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/feiern" element={<Feiern />} />
        <Route path="/unsere-weine" element={<Product />} />
      </Routes>
      <Module module="footer" footer={{ url: Background }} />
      <ToastContainer />
    </>
  );
}

export default Router;
