import axios from "axios"
import { useEffect, useState } from "react"
import Module from "../../library/module"
import GlobalBackground from "../../assets/img/global-bakground.webp"
import Element from "../../library/element"

function Product() {
    const [Data, setData] = useState([])
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_unsere_weine'`)
        if (result.data.length > 0) {
            const image = JSON.parse(result.data[0]["content"])
            var l = [];
            for (let i = 0; i < image.length; i++) {
                l[i] = {
                    status: true,
                    data:
                        <div className="loader-container border" style={{ height: "700px", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="loader"></div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="text-center loader-title mt-4">Wird geladen...</div>
                                </div>
                            </div>
                        </div>
                };
            }
            setData(l)
            setTimeout(() => {
                var r = [];
                for (let i = 0; i < image.length; i++) { r[i] = { status: false, data: image[i]["blob"] }; }
                setData(r)
            }, 2000);
        }
    }
    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="menu-title lg-pt-10 lg-pb-5 md-pb-2 md-pt-2"> Unsere Weine </div>
                <div className="row d-flex justify-content-center">
                    {
                        (Data || []).map((d, x) => {
                            return (
                                d["status"] ?
                                    <div className="col-lg-6 mb-4">
                                        {d["data"]}
                                    </div>
                                    :
                                    <div className="col-lg-6 mb-4">
                                        <img src={d["data"]} className="galery-image shadow-sm" />
                                    </div>
                            )
                        })
                    }
                </div>
            </Module>
        </>
    )
}

export default Product;