import { useEffect, useRef, useState } from "react";
import Module from "../../library/module";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import GlobalBackground from "../../assets/img/global-bakground.webp"
import Music from "../../assets/file/myway_arda.mp3"
import Element from "../../library/element";
import MobileVideo from "../../assets/file/mobile_video_olivio.mp4";
import VideoBackground from "../../assets/img/video-bg.jpg";



function Home() {
    const navigate = useNavigate();
    const videoref = useRef(null)
    const musicref = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false);
    const [Data, setData] = useState({
        banner: {
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" }
        },
        aboutus: {
            slogan: "",
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" }
        },
        video: {
            video: { id: 0, blob: "", file: "" },
            title: "",
            text: ""
        },
        welcome: {
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 4,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                }
            ],
            slogan: "",
            title: "",
            text: ""
        },
        artist: {
            bgblob: { id: 0, blob: "", file: "" },
            blob: { id: 0, blob: "", file: "" },
            title: "",
            artist: "",
            text: "",
            time: "",
            music: { id: 0, blob: "", file: "" }
        },
        menu: {
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
            ],
        },
        feiern: {
            slogan: "",
            title: "",
            text: "",
            phone: "",
            email: "",
            blob: { id: 0, blob: "", file: "" },
        }
    })
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242118' AND page_id='_page_home'`)
        setData(JSON.parse(result.data[0]["content"]))
        videoref.current.src = JSON.parse(result.data[0]["content"])["video"]["video"]["blob"]
    }
    const Play = () => {
        if (musicref.current.paused) {
            musicref.current.play();
            setIsPlaying(true);
        } else {
            musicref.current.pause();
            setIsPlaying(false);
        }
    };
    useEffect(() => { 
        Load()
     }, [])
    return (
        <>
            <Module module="section" section={{ type: "banner", url: Data["banner"]["blob"]["blob"] }}>
                <div className="container-inner">
                    <div className="row mb-5">
                        <div className="col-lg-6">
                            <div className="banner-info">
                                <div className="banner-title">
                                    {Data["banner"]["title"]}
                                </div>
                                <div className="banner-text">
                                    {Data["banner"]["text"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="content-inner lg-pt-2 lg-pb-2">
                            <div>
                                <img src={Data["aboutus"]["blob"]["blob"]} className="image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content-inner lg-pt-2 lg-pb-2 md-pb-2">
                            <div>
                                <div className="slogan">{Data["aboutus"]["slogan"]}</div>
                                <div className="title">{Data["aboutus"]["title"]}</div>
                                <div className="text" dangerouslySetInnerHTML={{ __html: Data["aboutus"]["text"] }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{ type: "video", url: GlobalBackground }}>
                <video controls ref={videoref} poster={VideoBackground} className="video d-lg-block d-md-none">
                    <source />
                </video>
                <video controls className="video d-lg-none" poster={VideoBackground}>
                    <source src={MobileVideo} />
                </video>
            </Module>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="content-inner lg-pt-5 lg-pb-5 md-pb-2">
                            <div className="w-100">
                                <div className="row w-100">
                                    {
                                        (Data["welcome"]["blob"] || []).map((d, x) => {
                                            return (
                                                <div className="col-lg-6 col-6 mb-2 mt-2 md-p-2">
                                                    <div className="card cursor-pointer border-0" onClick={() => { navigate(`${d["link"]}`) }}>
                                                        <div className="card-body p-0">
                                                            <div style={{ backgroundImage: `url(${d["blob"]["blob"]})` }} className="image-background"></div>
                                                        </div>
                                                        <div className="card-title mb-0">{d["title"]}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content-inner lg-pt-5 lg-pb-5 md-pb-2">
                            <div>
                                <div className="slogan">{Data["welcome"]["slogan"]}</div>
                                <div className="title">{Data["welcome"]["title"]}</div>
                                <div className="text" dangerouslySetInnerHTML={{ __html: Data["welcome"]["text"] }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{ type: "content", url: Data["artist"]["bgblob"]["blob"], classname: "container-inner" }}>
                <div className="row" >
                    <div className="col-lg-4">
                        <div className="content-inner lg-pt-10 lg-pb-10 md-pb-5">
                            <div>
                                <img src={Data["artist"]["blob"]["blob"]} className="artist-image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="content-inner lg-pt-10 lg-pb-10 md-pb-5">
                            <div className="artist-inner">
                                <div className="d-flex justify-content-center">
                                    <div className="d-lg-flex">
                                        <div className="artist-title">{Data["artist"]["title"]}</div>
                                        <div className="artist-name">{Data["artist"]["artist"]}</div>
                                        <div className="artist-icon"><i className="fa-solid fa-music"></i></div>
                                    </div>
                                </div>
                                <div className="artist-text" dangerouslySetInnerHTML={{ __html: Data["artist"]["text"] }}></div>
                                <div className="artist-time">
                                    <div className="d-block">
                                        <div>{Data["artist"]["time"]}</div>
                                        <video controls>
                                            <source src={Music} />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="content-inner lg-pt-5 lg-pb-5 md-pt-5 md-pb-3">
                    <div className="row w-100 d-flex justify-content-center">
                        {
                            (Data["menu"]["blob"] || []).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12 md-pb-2 md-p-2">
                                        <div className="card cursor-pointer border-0" onClick={() => { d["title"] == "HAUSLIEFERUNG" ? window.open(d["link"]) : navigate(`${d["link"]}`) }}>
                                            <div className="card-body p-0">
                                                <div style={{ backgroundImage: `url(${d["blob"]["blob"]})` }} className="menu-background"></div>
                                            </div>
                                            <div className="card-title">{d["title"]}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Module>
            <Module module="section" section={{ type: "content", url: GlobalBackground, classname: "container-inner" }}>
                <div className="row">
                    <div className="col-lg-6 p-2">
                        <div className="content-inner lg-pt-2 lg-pb-10 md-pb-5 mb-pt-3">
                            <div>
                                <div className="slogan">{Data["feiern"]["slogan"]}</div>
                                <div className="title">{Data["feiern"]["title"]}</div>
                                <div className="text" dangerouslySetInnerHTML={{ __html: Data["feiern"]["text"] }}></div>
                                <div className="d-lg-flex d-md-block">
                                    <div className="me-3 md-text-center" onClick={() => { window.open(`tel:${Data["feiern"]["phone"]}`) }}>
                                        <i className="fa-solid fa-phone mt-1 me-2"></i>
                                        <span className="text" style={{ fontWeight: "600" }}>{Data["feiern"]["phone"]}</span>
                                    </div>
                                    <div className="me-3 md-text-center" onClick={() => { window.open(`mail:${Data["feiern"]["email"]}`) }}>
                                        <i className="fa-solid fa-envelope mt-1 me-2"></i>
                                        <span className="text" style={{ fontWeight: "600" }}>{Data["feiern"]["email"]}</span>
                                    </div>
                                </div>
                                <div className="w-100 mt-4">
                                    <button className="btn btn-olivio w-100" onClick={() => { navigate(`/feiern`) }}>Detail</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 p-1">
                        <div className="content-inner lg-pt-2 lg-pb-10 md-pb-5">
                            <div>
                                <img src={Data["feiern"]["blob"]["blob"]} className="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Home;